<template>
  <div>
    <KTCodePreview v-bind:title="'Import Excel tạo Form Posm'">
      <template v-slot:preview>
        <b-form ref="form" class="row" enctype="multipart/form-data">
          <!-- Input session -->
          <b-container class="bv-example-row">
            <b-row>
              <b-col>
                <div class="form-group">
                  <span>
                    <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                    đúng format, hoặc có thể tải ở
                    <a :href="urlExcel">đây</a>
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row></b-row>
            <b-row>
              <b-col>
                <span> <b>Bước 2:</b> Tiến hành Import </span>
                <div style="padding-top: 15px">
                  <b-form-file
                    placeholder="Hãy chọn một tập tin"
                    @change="selectFile"
                    v-model="file"
                    ref="file-input"
                    class="mb-2"
                  ></b-form-file>
                </div>
                <b-progress :value="progress" :max="100" show-progress>
                  <b-progress-bar
                    :value="progress"
                    :label="`${progress.toFixed(2)}%`"
                  ></b-progress-bar>
                </b-progress>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col>
                <div style="padding-top: 15px">
                  <span><strong>Bước 3:</strong> Tạo Campaign</span>

                  <b-form-group
                    label="Điền tự động tên Campaign:"
                    label-for="input-campaign"
                    description="Tên Campaign sẽ là tên Sheet của file Excel mà bạn đã import"
                  >
                    <b-form-input
                      v-model="campaign.name"
                      type="text"
                      required
                      placeholder="Tên Campaign"
                    ></b-form-input>
                  </b-form-group>
                  <span>
                    <strong>Bước 4:</strong> Điền TG bắt đầu và kết thúc của
                    Campaign
                  </span>
                  <b-row>
                    <b-col sm="6">
                      <b-form-group
                        id="input-start-date"
                        label="Thời gian bắt đầu:"
                        label-for="input-start-date"
                      >
                        <date-picker
                          id="input-start-date"
                          placeholder="Thời gian bắt đầu"
                          class="form-control"
                          :config="dpConfigs.date"
                          required
                          v-model="campaign.startDate"
                          autocomplete="off"
                        ></date-picker>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6">
                      <b-form-group
                        id="input-end-date"
                        label="Thời gian kết thúc"
                        label-for="input-end-date"
                      >
                        <date-picker
                          id="input-end-date"
                          placeholder="Thời gian kết thúc"
                          class="form-control"
                          :config="dpConfigs.date"
                          required
                          v-model="campaign.endDate"
                          autocomplete="off"
                        ></date-picker>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <!-- End input session -->
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="selectedStatus"
                :aria-describedby="ariaDescribedby"
                name="radios-btn-default"
                buttons
                @change="onChangeStatus()"
              >
                <b-form-radio value="all"
                  >Tất cả
                  <span class="text-primary"
                    >({{ countAll }})</span
                  ></b-form-radio
                >
                <b-form-radio value="valid"
                  >Hợp lệ
                  <span class="text-danger"
                    >({{ countValid }})</span
                  ></b-form-radio
                >
                <b-form-radio value="error"
                  >Lỗi
                  <span class="text-warning"
                    >({{ countError }})</span
                  ></b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
            <div class="table-responsive">
              <b-table
                bordered
                hover
                :fields="fields"
                :items="importItems"
                :per-page="50"
                :current-page="currentPage"
                id="my-table-product"
                responsive
              >
                <template v-slot:cell(isValid)="row">
                  <b-button
                    variant="secondary"
                    disabled
                    v-if="row.item.isValid === null"
                  >
                    <b-spinner small></b-spinner>
                  </b-button>
                  <span
                    v-else
                    v-text="getStatusName(row.item)"
                    class="label font-weight-bold label-lg label-inline"
                    v-bind:class="{
                      'label-light-success': row.item.isValid === true,
                      'label-light-danger': row.item.isValid === false,
                    }"
                    style="width: max-content"
                  ></span>
                </template>
              </b-table>
            </div>
            <b-pagination
              v-show="importItems.length > 10"
              v-model="currentPage"
              :total-rows="importItems.length"
              :per-page="50"
              aria-controls="my-table-product"
              align="right"
            ></b-pagination>
          </b-container>
        </b-form>
      </template>
      <template v-slot:foot>
        <div class="bv-example-row container">
          <b-row>
            <b-button
              style="font-weight: 600; width: 60px"
              variant="primary"
              size="sm"
              @click="importPosmForm"
              :disabled="uploading || !file"
              >Lưu
            </b-button>
            <b-button
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="goToPosmOverviewClickHandler"
              >Hủy
            </b-button>
          </b-row>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>
<style scoped>
.table th,
.table td {
  text-align: center;
}

.myTable /deep/ .table {
  width: max-content !important;
  color: #464e5f;
  background-color: transparent;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import xlsx from 'xlsx';
import ApiService from '@/core/services/api.service';
import {
  cloneDeep,
  formatPrice,
  makeToastFaile,
  makeToastSuccess,
} from '@/utils/common';
import ExcelTableRow from '@/view/pages/posm/models/ExcelTableRow';
import {
  EMPTY_HEADER_REGEX,
  MAX_ROWS_ALLOW_IMPORTING,
  SUCCESS_RESPONSE_STATUS,
  WORKBOOK_READ_TYPE,
} from '@/utils/constants';
import { trim } from 'lodash';
import { DP_CONFIG_DATETIME } from '@/utils/date';
import { importPosmFormViaExcelFields as fields } from '@/view/pages/posm/table-setup';

export default {
  data() {
    return {
      formatPrice,
      fields,
      importItems: [],
      tmpItems: [],
      file: null,
      excelList: [],
      progress: 0,
      uploading: false,
      campaign: {
        name: '',
        startDate: '',
        endDate: '',
      },
      urlExcel:
        'https://docs.google.com/spreadsheets/d/1E2EeNm6ANEgmu7by7wQ2XQT3RV6OIfvm/edit?usp=share_link&ouid=115048073549091218274&rtpof=true&sd=true',
      currentPage: 1,
      selectedStatus: 'all',
      dpConfigs: DP_CONFIG_DATETIME,
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Quản lý POSM', route: '/posm/overview' },
      { title: 'Tạo Form Posm' },
    ]);
  },
  computed: {
    countAll() {
      return this.tmpItems.length;
    },
    countValid() {
      return this.tmpItems.filter((item) => item.isValid === true).length;
    },
    countError() {
      return this.tmpItems.filter((item) => item.isValid === false).length;
    },
  },
  methods: {
    convertPrice: function(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    importPosmForm: async function() {
      this.uploading = true;
      if (this.countError > 0) {
        makeToastFaile('Vui lòng xử lý dữ liệu lỗi hoặc xóa dữ liệu lỗi!');
        return;
      }
      if (!this.importItems) {
        return;
      }

      this.onCallApiSubmitExcelFile(this.importItems);
    },
    onCallApiSubmitExcelFile() {
      const payload = new FormData();
      payload.append('file', this.file);
      payload.append('campaignName', this.campaign.name);
      payload.append('startDate', this.campaign.startDate);
      payload.append('endDate', this.campaign.endDate);
      ApiService.post('posm-forms/submit-via-excel', payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.$router.push({
              name: 'posm-form-overview',
            });
          } else {
            makeToastFaile(message);
          }
          this.uploading = false;
        })
        .catch(({ response }) => {
          const { status, message } = response.data;
          if (status === 0) {
            makeToastFaile(message);
            this.uploading = false;
          }
        });
    },
    onValidateData(listData) {
      ApiService.post('posm-forms/validate-excel', listData)
        .then((response) => {
          const { status, message } = response.data;
          if (status === SUCCESS_RESPONSE_STATUS) {
            this.importItems = response.data.data;
            this.tmpItems = cloneDeep(this.importItems);
            this.onChangeStatus();
            this.progress = 100;
          } else {
            makeToastFaile(message);
          }
          this.uploading = false;
        })
        .catch((e) => {
          if (!e.response) {
            makeToastFaile('Đã có lỗi xảy ra, hãy thử lại');
          } else {
            makeToastFaile(e.response.data.message);
          }
          this.tmpItems = [];
          this.importItems = [];
          this.uploading = false;
        });
    },
    selectFile(event) {
      const files = event.target.files[0];
      this.file = files;
      this.importItems = [];
      this.tmpItems = [];
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx',
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: WORKBOOK_READ_TYPE,
          });
          const wsName = workbook.SheetNames[0];
          this.excelList = XLSX.utils.sheet_to_json(workbook.Sheets[wsName], {
            blankRows: false,
            defval: '',
          });
          if (this.excelList.length > MAX_ROWS_ALLOW_IMPORTING) {
            return alert(
              `Để đám bảo hệ thống hoạt động ổn định, chỉ hỗ trợ kích hoạt tối đa ${MAX_ROWS_ALLOW_IMPORTING} Vị trí hạng mục trên một lần thao tác!`,
            );
          }
          const commonExcelHeaders = [
            'Hạng mục',
            'Vị trí hạng mục',
            'Loại trường dữ liệu',
            'Vị trí',
            'STT',
            'Kích thước',
            'Độ ưu tiên',
            'Thời gian bắt đầu',
            'Thời gian kết thúc',
            'Ghi chú',
          ];
          let storeNameHeaders = Object.keys(this.excelList[0]).filter(
            (key) => {
              if (
                !commonExcelHeaders.includes(key) &&
                !key.match(EMPTY_HEADER_REGEX)
              ) {
                return trim(key);
              }
            },
          );

          const statuses = this.fields.slice(-2);
          this.fields = this.fields
            .slice(0, commonExcelHeaders.length)
            .concat(
              storeNameHeaders.map((name) => {
                return {
                  key: trim(name),
                  label: trim(name),
                  thStyle: { width: '5%' },
                };
              }),
            )
            .concat(statuses);
          this.campaign.name = wsName;
          this.importItems = this.excelList.map((item) => {
            return Object.assign(
              {},
              new ExcelTableRow(
                item['Hạng mục'],
                item['Vị trí hạng mục'],
                item['Loại trường dữ liệu'],
                item['Vị trí'],
                item['Kích thước'],
                item['Độ ưu tiên'],
                item['Thời gian bắt đầu'],
                item['Thời gian kết thúc'],
                item['Ghi chú'],
                storeNameHeaders,
                item,
                null,
                '',
              ),
            );
          });

          this.tmpItems = cloneDeep(this.importItems);
          this.onValidateData(this.tmpItems);
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    onChangeStatus() {
      switch (this.selectedStatus) {
        case 'all':
          this.importItems = cloneDeep(this.tmpItems);
          break;
        case 'valid':
          this.importItems = this.tmpItems.filter(
            (tmpItem) => tmpItem.isValid === true,
          );
          break;
        case 'error':
          this.importItems = this.tmpItems.filter(
            (tmpItem) => tmpItem.isValid === false,
          );
          break;
      }
    },
    getStatusName: function(item) {
      return item.isValid ? 'Hợp lệ' : 'Lỗi';
    },
    goToPosmOverviewClickHandler() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
